<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="70%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- bulan -->
            <ARow class="form-row">
                <ACol :xl="12" :md="24">
                    <AFormItem
                        label="Pilih Bulan"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="bulan"
                        has-feedback>
                        <AMonthPicker
                            v-model:value="form.bulan"
                            :allow-clear="false"
                            placeholder="Pilih Bulan"
                            :disabled-date="disabledDate"
                            format="MMMM YYYY"
                            @change="fetchTargetDay"
                            :disabled="form.readOnly"
                            style="width:100%;"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- pilih user TSO -->
            <ARow
                v-if="hasRoles([ROLE_ADMIN_BK])"
                class="form-row">
                <ACol :xl="12" :md="24">
                    <AFormItem
                        label="Pilih User TSO"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="useram_id"
                        has-feedback>
                        <FilterUsers
                            v-model:value="form.useram_id"
                            :status_mapping="1"
                            :disabled="form.readOnly || !form.bulan"
                            :mode="null"
                            style="width:100%;"
                            placeholder="Pilih User TSO"
                            :join-options="
                                form.useram_id && item.id
                                    ? [{ id: form.useram_id, name: item.useram_name }]
                                    : []"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- pilih salesman -->
            <ARow class="form-row">
                <ACol :xl="12" :md="24">
                    <AFormItem
                        label="Pilih Salesman"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="salesman_id"
                        has-feedback>
                        <FilterSalesman
                            v-model:value="form.salesman_id"
                            :useram_id="form.useram_id"
                            :disabled="form.readOnly || !form.bulan"
                            @change="() => {fetchTargetCustomer(); fetchByUserList();}"
                            :mode="null"
                            style="width:100%;"
                            :join-options="
                                form.salesman_id
                                    ? [{ id: item.salesman_id, nopeg: item.salesman_nopeg, name: item.salesman_name }]
                                    : []"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- row child 1 -->
            <ARow class="form-row">
                <ACol :xl="12" :md="24">
                    <AFormItem
                        label="Target Hari Kerja"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="target_hari_kerja"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.target_hari_kerja"
                            :disabled="form.readOnly">
                            <template #suffix>
                                <ATooltip
                                    v-if="state.label.target_hari_kerja"
                                    :title="`Target Hari Kerja telah di inputkan oleh ${state.label.user_name} dengan nilai ${getLabelNull(state.label.target_hari_kerja)}`">
                                    <InfoCircleOutlined style="color: rgba(0, 0, 0, 0.45)" />
                                </ATooltip>
                            </template>
                        </AInput>
                    </AFormItem>
                </ACol>
                <ACol :xl="12" :md="24">
                    <AFormItem
                        label="Target Visit"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="target_visit"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.target_visit"
                            :disabled="form.readOnly">
                            <template #suffix>
                                <ATooltip
                                    v-if="state.label.target_visit"
                                    :title="`Target Visit telah di inputkan oleh ${state.label.user_name} dengan nilai ${getLabelNull(state.label.target_visit)}`">
                                    <InfoCircleOutlined style="color: rgba(0, 0, 0, 0.45)" />
                                </ATooltip>
                            </template>
                        </AInput>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- row child 2 -->
            <ARow class="form-row">
                <ACol :xl="12" :md="24">
                    <AFormItem
                        label="Target Coverage"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="target_coverage"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.target_coverage"
                            :disabled="form.readOnly">
                            <template #suffix>
                                <ATooltip
                                    v-if="state.label.target_coverage"
                                    :title="`Target Coverage telah di inputkan oleh ${state.label.user_name} dengan nilai ${getLabelNull(state.label.target_coverage)}`">
                                    <InfoCircleOutlined style="color: rgba(0, 0, 0, 0.45)" />
                                </ATooltip>
                            </template>
                        </AInput>
                    </AFormItem>
                </ACol>
                <ACol :xl="12" :md="24">
                    <AFormItem
                        label="Target Customer Active"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="target_customer_active"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.target_customer_active"
                            :disabled="form.readOnly">
                            <template #suffix>
                                <ATooltip
                                    v-if="state.label.target_customer_active"
                                    :title="`Target Customer Active telah di inputkan oleh ${state.label.user_name} dengan nilai ${getLabelNull(state.label.target_customer_active)}`">
                                    <InfoCircleOutlined style="color: rgba(0, 0, 0, 0.45)" />
                                </ATooltip>
                            </template>
                        </AInput>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- row child 3 -->
            <ARow class="form-row">
                <ACol :xl="12" :md="24">
                    <AFormItem
                        label="Target Volume"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="target_volume"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.target_volume"
                            :disabled="form.readOnly">
                            <template #suffix>
                                <ATooltip
                                    v-if="state.label.target_volume"
                                    :title="`Target Volume telah di inputkan oleh ${state.label.user_name} dengan nilai ${getLabelNull(state.label.target_volume)}`">
                                    <InfoCircleOutlined style="color: rgba(0, 0, 0, 0.45)" />
                                </ATooltip>
                            </template>
                        </AInput>
                    </AFormItem>
                </ACol>
                <ACol :xl="12" :md="24">
                    <AFormItem
                        label="Target New Outlet"
                        label-align="left"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="target_new_outlet"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.target_new_outlet"
                            :disabled="form.readOnly">
                            <template #suffix>
                                <ATooltip
                                    v-if="state.label.target_new_outlet"
                                    :title="`Target New Outlet telah di inputkan oleh ${state.label.user_name} dengan nilai ${getLabelNull(state.label.target_new_outlet)}`">
                                    <InfoCircleOutlined style="color: rgba(0, 0, 0, 0.45)" />
                                </ATooltip>
                            </template>
                        </AInput>
                    </AFormItem>
                </ACol>
            </ARow>

            <ARow
                v-if="!form.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="state.errors">
            <ASpace>
                <AAlert
                    v-for="(item, index) in state.errors"
                    type="error"
                    :key="index"
                    :message="item"
                    banner
                    closable
                    @close="state.errors = []" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    computed,
} from 'vue'
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import FilterSalesman from '@/components/filter/FilterSalesman'
import FilterUsers from '@/components/filter/FilterUsers'
import Form from 'vform'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import moment from 'moment'
import { debounce, pickBy, toNumber } from 'lodash'
import localStorage from 'store'
import {
    ROLE_TSO,
    ROLE_ADMIN_BK,
    hasRoles,
} from '@/helpers'

export default defineComponent({
    components: {
        InfoCircleOutlined,
        FilterSalesman,
        FilterUsers,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const user = computed(() => localStorage.get('profile'))

        const form = reactive(new Form({
            id: props.item.id,
            bulan: null,
            useram_id: [],
            salesman_id: [],
            target_hari_kerja: null,
            target_visit: null,
            target_coverage: null,
            target_customer_active: null,
            target_volume: null,
            target_new_outlet: null,
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat Data Target Salesman',
            endpoint: '/api/atsalesman/target',
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                bulan: [
                    {
                        required: true,
                        message: 'Bulan tidak boleh kosong!',
                    },
                ],
                useram_id: [
                    {
                        required: true,
                        message: 'User TSO tidak boleh kosong!',
                    },
                ],
                salesman_id: [
                    {
                        required: true,
                        message: 'Salesman tidak boleh kosong!',
                    },
                ],
                target_hari_kerja: [
                    {
                        required: true,
                        message: 'Target hari kerja tidak boleh kosong!',
                    },
                ],
                target_visit: [
                    {
                        required: true,
                        message: 'Target visit tidak boleh kosong!',
                    },
                ],
                target_coverage: [
                    {
                        required: true,
                        message: 'Target coverage tidak boleh kosong!',
                    },
                ],
                target_customer_active: [
                    {
                        required: true,
                        message: 'Target customer active tidak boleh kosong!',
                    },
                ],
                target_volume: [
                    {
                        required: true,
                        message: 'Target volume tidak boleh kosong!',
                    },
                ],
                target_new_outlet: [
                    {
                        required: true,
                        message: 'Target new outlet tidak boleh kosong!',
                    },
                ],
            },
            errors: [],
            label: new Form({
                user_name: '',
                target_hari_kerja: null,
                target_visit: null,
                target_coverage: null,
                target_customer_active: null,
                target_volume: null,
                target_new_outlet: null,
            }),
            errors: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const disabledDate = current => {
            // TODO: sementara waktu untuk testing
            // if (current.isSame(new Date(), 'month')) {
            //     return moment().startOf('month').add(10, 'days').isBefore(current)
            // }

            return current && current < moment().startOf('month')
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            if (!form.id) {
                form.post(state.endpoint)
                    .then(({ data }) => {
                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, status } }) => {
                        if (data) {
                            state.errors = data.errors
                        }
                    })
            } else {
                form.put(`${state.endpoint}/${form.id}`)
                    .then(({ data }) => {
                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, status } }) => {
                        if (data) {
                            state.errors = data.errors
                        }
                    })
            }

        }

        const fetchTargetDay = () => {
            apiClient.get('/api/atsalesman/target-days', {
                    params: pickBy({month: moment(form.bulan).format('YYYY-MM')}),
                })
                .then(({ data }) => {
                    form.target_hari_kerja = data.sales.target_hari_kerja
                    form.target_visit = data.sales.target_visit
                })
        }

        const fetchTargetCustomer = debounce(() => {
            apiClient.get('/api/atsalesman/target-customers', {
                    params: pickBy({
                        month: moment(form.bulan).format('YYYY-MM'),
                        salesman_id: form.salesman_id,
                        useram_id: form.useram_id,
                    }),
                })
                .then(({ data }) => {
                    form.target_coverage = data.sales.target_coverage
                    form.target_customer_active = data.sales.target_customer_active
                })
        }, 50)

        const fetchByUserList = debounce(() => {
            state.label.reset()

            apiClient.get('/api/atsalesman/target-tooltips', {
                    params: pickBy({
                        month: moment(form.bulan).format('YYYY-MM'),
                        salesman_id: form.salesman_id,
                        useram_id: form.useram_id,
                    }),
                })
                .then(({ data }) => {
                    if (data.users) {
                        data.users.forEach(item => {
                            state.label.user_name = item.useram_name
                            state.label.target_hari_kerja = item.target_hari_kerja
                            state.label.target_visit = item.target_visit
                            state.label.target_coverage = item.target_coverage
                            state.label.target_customer_active = item.target_customer_active
                            state.label.target_volume = item.target_volume
                            state.label.target_new_outlet = item.target_new_outlet
                        })
                    }
                })
        }, 50)

        const getLabelNull = (val) => {
            return val || 0
        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            } else {
                form.bulan = props.item.bulan
                form.salesman_id = props.item.salesman_id
                form.target_hari_kerja = props.item.target_hari_kerja
                form.target_visit = props.item.target_visit
                form.target_coverage = props.item.target_coverage
                form.target_customer_active = props.item.target_customer_active
                form.target_volume = props.item.target_volume
                form.target_new_outlet = props.item.target_new_outlet
                form.readOnly = props.item.readOnly
            }

            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} Data Target Salesman`
            }

            form.useram_id = hasRoles([ROLE_TSO]) ? user.value.id : props.item.useram_id
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            disabledDate,
            fetchTargetDay,
            fetchTargetCustomer,
            fetchByUserList,
            getLabelNull,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
